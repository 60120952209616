import './App.css';
import React, {useState} from 'react';
import Background from './components/Background';
import DiscordWidget from './components/DiscordWidget';
import Enter from './components/Enter';
import { Analytics } from '@vercel/analytics/react';

const App = () => {
  const [entered, setEntered] = useState(false);

  const handleEnter = () => {
    setEntered(true);
  };

  return (
    <div className="overflow-hidden h-screen md:flex justify-center items-center bg-black">
      <Analytics />
      {!entered ? (
        <button
          onClick={handleEnter}
          className="size-full bg-black font-momo text-white font-bold py-2 px-18 rounded focus:outline-none focus:shadow-outline"
        >
          click to enter
        </button>
      ) : (
        <div className="h-screen md:flex items-center justify-center">
          <Background />
        </div>
      )}
    </div>
  );
};

export default App;
