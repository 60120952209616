import React from 'react'
import backgroundVideo from './backgroundVideo3.mp4'
import backgroundAudio from './backgroundAudio3.ogg'
import AudioPlayer from 'react-audio-player';
import DiscordWidget from './DiscordWidget';

export default function Background() {
    return (
      <div className="relative flex-col items-center justify-center h-full">
        <AudioPlayer
          autoPlay
          loop
          muted
          src={backgroundAudio}
          onPlay={(e) => console.log(e)}
        />
        <div className="absolute inset-0 flex-col items-center justify-center z-20 md:z-10">
          <DiscordWidget />
        </div>
        <video autoPlay loop className="absolute h-screen w-full min-w-full min-h-full max-w-none" id="video">
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      </div>
    )
  }
